/**
 * components/Button/Disabled.js
 */
import PropTypes from 'prop-types';

const ButtonDisabled = ({ buttonProps, className, children }) => (
  <button
    disabled
    className={`${className} opacity-60 relative`}
    type="button"
    onClick={(evt) => evt.preventDefault()}
    {...buttonProps}
  >
    {children}
  </button>
);

ButtonDisabled.propTypes = {
  children: PropTypes.node,
  buttonProps: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ButtonDisabled;
