
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  accessTokenExpired: null,
  isRegistered: false,
  refreshToken: null,
  userData: null,
  fcmToken: null,
  loginSource: '',
  nudge: null,
  webVersion: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const {
        accessToken,
        tokenExpired,
        refreshToken,
      } = action.payload;
      state.accessToken = accessToken;
      state.accessTokenExpired = tokenExpired;
      state.refreshToken = refreshToken;
      state.userData = action.payload;
    },
    setIsRegistered: (state, action) => {
      state.isRegistered = action.payload;
    },
    setUserData: (state, action) => {
      const newData = { ...state.userData, ...action.payload };
      state.userData = newData;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setLoginSource: (state, action) => {
      state.loginSource = action.payload;
    },
    setNudge: (state, action) => {
      state.nudge = {
        active: action.payload.active ?? false,
        firstTime: action.payload.firstTime ?? false
      };
    },
    setWebVersion: (state, action) => {
      state.webVersion = action.payload;
    },
    clearAuthData: (state) => {
      state.accessToken = null;
      state.accessTokenExpired = null;
      state.refreshToken = null;
      state.userData = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const { setAuthData, clearAuthData, setNudge, setIsRegistered, setUserData, setFcmToken, setLoginSource } = authSlice.actions;

export default authSlice.reducer;

export const getIsLogin = (state) => !!state.auth.accessToken;
export const getIsRegistered = (state) => state.auth.isRegistered;
export const getUserData = (state) => state.auth.userData;
export const getAccessToken = (state) => state.auth.accessToken;
export const getAccessTokenExpired = (state) => state.auth.accessTokenExpired;
export const getRefreshToken = (state) => state.auth.refreshToken;
export const getFcmToken = (state) => state.auth.fcmToken;
export const getLoginSource = (state) => state.auth.loginSource;
export const getNudge = (state) => state.auth.nudge;
export const getWebVersion = (state) => state.auth.webVersion;
