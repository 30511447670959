'use client'

import dynamic from "next/dynamic";
import React from "react";
import { useSelector } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import useScrollTracking from "@/core/helpers/useScrollTracking";
import { getIsErrorServer, getIsTimeout } from "@/core/store/reducer/apiSlice";

import ErrorServer from "@/app/500";
import Timeout from "@/app/timeout";

import ModalLogin from "@/components/login/Modal";
import ModalRegister from "@/components/register/Modal";

const Navbar = dynamic(() => import("@/components/Navbar"));
const Footer = dynamic(() => import("@/components/Footer"));

export default function LayoutPage({ children, ...props }) {
    useScrollTracking();

    const isTimeout = useSelector(getIsTimeout);
    const isErrorServer = useSelector(getIsErrorServer);

    if (isTimeout) return <Timeout />
    if (isErrorServer) return <ErrorServer />

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={
                process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY
            }
            scriptProps={{
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined,
            }}
            useEnterprise={true}
        >
            <main className="flex flex-col min-h-screen">
                <Navbar enableHeaderIdealPay={props?.enableHeaderIdealPay} enableIdealPay={props?.enableIdealPay} showMenu={true} />
                <div>
                    {children}
                </div>
                <Footer enableIdealPay={props?.enableIdealPay} />

                <ModalLogin />
                <ModalRegister />
            </main>
        </GoogleReCaptchaProvider>
    );
}
