// Import Library
import dynamic from 'next/dynamic';

// Import Component
const ServerErrorPage = dynamic(() => import("@/app/500-page"));

export const metadata = {
    title: 'Server Error - IDEAL Indonesia'
}

export default function ServerError() {
    return <ServerErrorPage />
}
