import Api from "@/configs/Api";
import CryptoJS from "crypto-js";

import { ServiceGenerateSignature } from '@/app/services';

/**
 * 
 * @param {string | URL | Request} url 
 * @param {RequestInit} init 
 */
export const useFetch = async (url, params = null, init) => {
    const arg = url?.split('?');
    let headers = await Api.headers(arg?.[0]);
    let baseURL = process.env.NEXT_PUBLIC_API_URL;

    if (url?.includes('/api-pay')) baseURL = process.env.NEXT_PUBLIC_API_PAY_URL;

    if (init?.method?.toLowerCase() === 'get') {
        params = params ? new URLSearchParams(params) : null;
        url = url?.replace('/api-dev', '');
        url = url?.replace('/api-pay', '');

        return await fetch(`${baseURL}${url}${arg?.length > 1 ? '' : params ? `?${params}` : ''}`, {
            ...init,
            headers: Object.assign(headers, init?.headers),
        });
    }

    if (init?.method?.toLowerCase() === 'post') {
        params = params ? new URLSearchParams(params) : null;
        url = url?.replace('/api-dev', '');
        url = url?.replace('/api-pay', '');

        return await fetch(`${baseURL}${url}`, {
            ...init,
            headers: Object.assign(headers, init?.headers),
            body: JSON.stringify(params)
        });
    }
}

export const signature = async (urlApi = '') => {
    const url = CryptoJS.AES.encrypt(urlApi, process.env.NEXT_PUBLIC_API_TOKEN).toString()
    const signature = await ServiceGenerateSignature({ url: url });

    const bytes = CryptoJS.AES.decrypt(signature, process.env.NEXT_PUBLIC_API_TOKEN);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return data;
}