/**
 * components/Button/index.js
 */
import PropTypes from 'prop-types';

import Main from './Main';
import Content from './Content';
import Disabled from './Disabled';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Button = ({
  path,
  handler,
  variant = 'primary',
  size = 'lg',
  isLinkOutside,
  loading,
  disabled,
  buttonProps,
  full = false,
  className,
  ...others }) => {

  let buttonClasses = 'px-16 rounded-full text-md font-bold block text-center';
  if (size === 'sm') {
    buttonClasses += ' py-2 text-xs md:text-sm';
  } else {
    buttonClasses += ' py-3 text-sm md:text-base';
  }
  if (disabled) {
    buttonClasses += ' bg-neutral-20 text-neutral-60';
  } else {
    if (variant === 'secondary') {
      buttonClasses += ' bg-secondary-40 text-white';
    } else if (variant === 'secondary_line') {
      buttonClasses += ' border border-secondary-40 text-secondary-40 bg-transparent';
    } else if (variant === 'primary_line') {
      buttonClasses += ' border border-success-50 text-success-50 bg-transparent';
    } else if (variant === 'error') {
      buttonClasses += ' bg-danger-40 text-white';
    } else if (variant === 'error_line') {
      buttonClasses += ' border border-danger-40 text-danger-40 bg-transparent';
    } else {
      buttonClasses += ' bg-success-50 text-white';
    }
  }
  if (full) {
    buttonClasses += ' w-full';
  }
  if (className) {
    buttonClasses += ` ${className}`;
  }

  if (loading) {
    return (
      <Disabled className={buttonClasses} buttonProps={buttonProps}>
        <div className='invisible'>
          <Content {...others} />
        </div>
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
          <LoadingSpinner />
        </div>
      </Disabled>
    );
  }

  if (disabled) {
    return (
      <Disabled className={buttonClasses} buttonProps={buttonProps}>
        <Content {...others} />
      </Disabled>
    );
  }

  return (
    <Main
      path={path}
      handler={handler}
      isLinkOutside={isLinkOutside}
      buttonProps={buttonProps}
      className={buttonClasses}
      target={others.target}
    >
      <Content {...others} />
    </Main>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf([
    'error',
    'primary',
    'secondary',
    'error_line',
    'secondary_line',
    'primary_line',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),  label: PropTypes.node,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLinkOutside: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  full: PropTypes.bool,
  handler: PropTypes.func,
  buttonProps: PropTypes.object,
  className: PropTypes.string,
};

export default Button;
